
import { defineComponent, PropType } from 'vue';
import { GradedEvent } from '@/models';

export default defineComponent({
  props: {
    event: {
      type: Object as PropType<GradedEvent>
    }
  }
});
