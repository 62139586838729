
import { defineComponent, PropType } from 'vue';
import EventCard from '@/components/event/EventCardComponent.vue';

export default defineComponent({
  components: { EventCard },
  props: {
    scheduledEvents: {
      type: Object as PropType<Array<Event>>,
      required: true
    }
  }
});
